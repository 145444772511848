@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */

/** swiper */
.swiper-container {
  width: 50%;
  height: 300px;
  margin: 50px auto;
}

.swiper-slide {
  background: #fff;
  color: #000;
  text-align: center;
  min-height: 230px;
}
/** end of swiper */

/** scroll */
:root {
  scroll-behavior: smooth;
}
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
}
*:hover::-webkit-scrollbar-thumb {
  border: 3px solid rgb(198, 198, 198);
}
/** end of scroll */

/** snackbar */
.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #ffffff;
  --mdc-snackbar-supporting-text-color: #ffffff;

  &.snackbar-error {
    --mdc-snackbar-container-color: #c91818;
  }

  &.snackbar-success {
    --mdc-snackbar-container-color: #0aa32b;
  }
  &.snackbar-info {
    --mdc-snackbar-container-color: orange;
  }
}
/** end of snackbar */

.feedback-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: #ffffffa6;
}
